import React, { Component } from 'react';

class FourAns extends Component {
    constructor(props) {
        super(props);
        this.state = { 
         }
    }
    render() { 
        const {question: qs,num,selected,selectElement,selectAnswer,questionOptionsOpen,updateTrue} = this.props;
        return ( <>
        
            <div className="content text-center">
                <div className="options"  onClick={()=> questionOptionsOpen(selected)}>
                    <i className="fa fa-cogs"></i>
                </div>
                <div className="row">
                    <div className="col-4">
                        <b> {selected+1}-{num} </b>
                    </div>
                    <div className="col-4">
                        <b>{qs.time} sec</b>
                    </div>
                    <div className="col-4">
                        <b>Score: {qs.score}</b>
                    </div>
                </div>
                <div className="title" onClick={()=> selectElement(selected)} style={{backgroundColor:qs.contentBG,color:qs.contentColor}}>
                    {qs.content}
                </div>
                <div className="answers">
                    <div className="row">
                        {/* {num > 0 ?ansTemplate : null} */}
                        <div className="col-md-6">
                            <div className="ans" onClick={()=>selectAnswer(0)} style={{backgroundColor:qs.answers[0].contentBG,color:qs.answers[0].contentColor}}>
                                {qs.answers[0].content} 
                                <div className={qs.answers[0].isTrue?"status true-ans":"status"} onClick={() => updateTrue(0)}></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ans" onClick={()=>selectAnswer(1)} style={{backgroundColor:qs.answers[1].contentBG,color:qs.answers[1].contentColor}}>
                                {qs.answers[1].content} 
                                <div className={qs.answers[1].isTrue?"status true-ans":"status"} onClick={() => updateTrue(1)}></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ans" onClick={()=>selectAnswer(2)} style={{backgroundColor:qs.answers[2].contentBG,color:qs.answers[2].contentColor}}>
                                {qs.answers[2].content} 
                                <div className={qs.answers[2].isTrue?"status true-ans":"status"} onClick={() => updateTrue(2)}></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ans" onClick={()=>selectAnswer(3)} style={{backgroundColor:qs.answers[3].contentBG,color:qs.answers[3].contentColor}}>
                                {qs.answers[3].content} 
                                <div className={qs.answers[3].isTrue?"status true-ans":"status"} onClick={() => updateTrue(3)}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </> );
    }
}
 
export default FourAns;