import LeftSideBar from "./Components/LeftSideBar";
import FourAns from "./Components/Questions/FourAns";
import RightSideBar from "./Components/RightSideBar";
import React, { Component } from 'react';
import Options from "./Components/Options";
import Answers from "./Components/Answers";
import Live from "./Components/Live/Live";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      questions:[],
      selectedQuestion:null,
      isLive:false
    }
  }
  addNewSlide = () =>{
    const newState = {...this.state};
    newState.questions.push({
      content:" Click To Edit question!",
      answers:[
        {content:" Click To Edit Answer ",contentBG:"#fff",contentColor:"#000",isTrue:true},
        {content:" Click To Edit Answer ",contentBG:"#fff",contentColor:"#000",isTrue:false},
        {content:" Click To Edit Answer ",contentBG:"#fff",contentColor:"#000",isTrue:false},
        {content:" Click To Edit Answer ",contentBG:"#fff",contentColor:"#000",isTrue:false}
      ],
      contentBG:"#fff",
      contentColor:"#000",
      time:10,
      score:10,
      total:0,
      selectedElement:null,
      selectedAnswer:null,
      questionOptions:null,
    });
    this.setState(newState)
  }

  removeSlide = (index) =>{
    let conf = window.confirm('Are You sure ?');
    if(conf){
      // let newQuestions = [...this.state.questions];
      // newQuestions.splice(index,1);
      // this.setState(newQuestions);
      let newState = {...this.state};
      let questions = [...newState.questions];
      if(this.state.selectedQuestion === index){
        newState.selectedQuestion = null;
      }
      
      if(this.state.questions.length === 0){
        newState.selectedQuestion = null;
      }
      newState.questions.splice(index,1);
      // newState.questions = questions;
      this.setState({questions});
    }
  }

  selectQuestion = (index) =>{
    const newState = {...this.state};
    newState.selectedQuestion = index;
    this.setState(newState);
  }
  
  selectElement = (index) =>{
    const newState = {...this.state};
    newState.selectedElement = index;
    newState.selectedAnswer = null;
    newState.questionOptions = null;
    this.setState(newState);
  }

  updateQuestionTitle = (e,index) => {
    const newState = {...this.state};
    newState.questions[index].content = e.target.value;
    this.setState(newState);
  }

  updateQuestionBG = (color,index) => {
    const newState = {...this.state};
    newState.questions[index].contentBG = color.hex;
    this.setState(newState);
  }

  updateQuestionColor = (color,index) => {
    const newState = {...this.state};
    newState.questions[index].contentColor = color.hex;
    this.setState(newState);
  }
 
  questionOptionsOpen = (index) =>{
    const newState = {...this.state};
    newState.questionOptions = index;
    newState.selectedElement = null;
    newState.selectedAnswer = null;

    this.setState(newState);
  }
 
  selectAnswer = (index) =>{
    const newState = {...this.state};
    newState.selectedAnswer = index;
    newState.selectedElement = null;
    newState.questionOptions = null;

    this.setState(newState);
  }

  updateAnswerTitle = (e,selected) => {
    const newState = {...this.state};
    newState.questions[newState.selectedQuestion].answers[selected].content = e.target.value;
    this.setState(newState);
  }

  updateAnswerBG = (color,index) => {
    const newState = {...this.state};
    newState.questions[newState.selectedQuestion].answers[newState.selectedAnswer].contentBG = color.hex;
    this.setState(newState);
  }

  updateAnswerColor = (color) => {
    const newState = {...this.state};
    newState.questions[newState.selectedQuestion].answers[newState.selectedAnswer].contentColor = color.hex;
    this.setState(newState);
  }
  updateScore = (e) => {
    const newState = {...this.state};
    newState.questions[newState.selectedQuestion].score = parseInt(e.target.value);
    this.setState(newState);
  }
  updateTime = (e) => {
    const newState = {...this.state};
    newState.questions[newState.selectedQuestion].time = parseInt(e.target.value);
    this.setState(newState);
  }
  changeLiveStatus = (e) => {
    const newState = {...this.state};
    newState.isLive = !newState.isLive;
    this.setState(newState);
  }
  updateTrue = (ansIndex) => {
    const newState = {...this.state};
    newState.questions[newState.selectedQuestion].answers.map((item,index)=>{
      newState.questions[newState.selectedQuestion].answers[index].isTrue = false;

    });
    newState.questions[newState.selectedQuestion].answers[ansIndex].isTrue = !newState.questions[newState.selectedQuestion].answers[ansIndex].isTrue;
    this.setState(newState);
  }

  render() { 
    const {selectedQuestion,questions,selectedElement,selectedAnswer,questionOptions} = this.state;
    return (
    <div className="App">
      {this.state.isLive?<Live questions={questions} changeLiveStatus={this.changeLiveStatus} />:null}
      <LeftSideBar questions={questions} changeLiveStatus={this.changeLiveStatus} selectQuestion={this.selectQuestion} selected={selectedQuestion} removeSlide={this.removeSlide} addNewSlide={this.addNewSlide} />
      
      {(selectedQuestion !== null && questions.length !== 0)? <FourAns  updateTrue={this.updateTrue} questionOptionsOpen={this.questionOptionsOpen} selectElement={this.selectElement} selectAnswer={this.selectAnswer}  question={questions[selectedQuestion]}  num={questions.length} selected={selectedQuestion} />:null}

      {(selectedElement != null)? <RightSideBar selected={selectedQuestion} updateQuestionBG={this.updateQuestionBG} updateQuestionColor={this.updateQuestionColor}  updateQuestionTitle={this.updateQuestionTitle} question={questions[selectedQuestion]}/> :null}

      {(selectedAnswer != null)? <Answers selected={selectedAnswer} updateQuestionBG={this.updateAnswerBG} updateQuestionColor={this.updateAnswerColor}  updateAnswerTitle={this.updateAnswerTitle} question={questions[selectedQuestion].answers[selectedAnswer]}/> :null}
      
      {(questionOptions != null && questions.length > 0)? <Options selected={selectedQuestion} updateTime={this.updateTime} updateScore={this.updateScore} question={questions[selectedQuestion]} /> :null}
      
    </div>
  );
  }
}

export default App;
