import React, { Component } from 'react';

let int;
class Live extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            questions : [...this.props.questions],
            selected: 0 ,
            score:0,
            showResult:false,
            true:[],
            false:[]
         }
         
        }
        select = (isTrue) =>{
            let question = this.props.questions[this.state.selected];
            if((this.state.selected + 1) === this.state.questions.length){
                clearInterval(int);
                this.setState({ showResult:true });
            }else{
                this.setState({ selected:this.state.selected +1  });
            }
            if(isTrue){
                let trueQuestions = [...this.state.true];
                trueQuestions.push(question)
                this.setState({ true:trueQuestions , score: this.state.score + question.score, });
            }else{
                let falseQuestions = [...this.state.false];
                falseQuestions.push(question)
                this.setState({ false:falseQuestions  });
                
            }
        }
        componentDidMount(){
            this.setState({ questions:[...this.props.questions]  });
            int = setInterval(() => {
                const newstate = [...this.state.questions];
            let time =  newstate[this.state.selected].time--;
            this.setState({ questions: newstate  });
            console.log(time);
            if(time <= 1){
                console.log(this.state.questions);
                console.log(this.props.questions);
                clearInterval(int);
                let falseQuestions = [...this.state.false];
                falseQuestions.push(this.state.questions[this.state.selected]);
                this.setState({ false:falseQuestions ,showResult:true  });
            }
        }, 1000);
    }
    render() { 
        const {questions,selected,score} = this.state;
        const falseTemplate = this.state.false.map((item,index)=>(<p key={index}>
            {item.content}
        </p>));
        
        return ( <>
            <div className="live">
            {this.state.showResult?<div className="result">
                <h4> Result </h4>
                <p><b>Score</b></p>
                <h1> {this.state.score} <sub></sub> </h1>
                {this.state.false.length > 0 ?<div className="false">
                    {falseTemplate}
                </div>:null}
                <button className="btn btn-outline-danger" onClick={() => this.props.changeLiveStatus()}> Back To Edit </button>
            </div>:null}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="question-container text-center">
                                <div className="row">
                                    <div className="col-4"> <b> {selected+1} - {questions.length} </b> </div>
                                    <div className="col-4"> <b> {questions[selected].time} sec </b> </div>
                                    <div className="col-4"> <b> Total Score: {score} </b> </div>
                                    
                                    <div className="col-12">
                                        <h2 className="question" style={{background:questions[selected].contentBG,color:questions[selected].contentColor}}>
                                            {questions[selected].content}
                                        </h2>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="ans" onClick={()=>this.select(questions[selected].answers[0].isTrue)} style={{background:questions[selected].answers[0].contentBG,color:questions[selected].answers[0].contentColor}}>
                                            {questions[selected].answers[0].content}
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="ans" onClick={()=>this.select(questions[selected].answers[1].isTrue)} style={{background:questions[selected].answers[1].contentBG,color:questions[selected].answers[1].contentColor}}>
                                            {questions[selected].answers[1].content}
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="ans" onClick={()=>this.select(questions[selected].answers[2].isTrue)} style={{background:questions[selected].answers[2].contentBG,color:questions[selected].answers[2].contentColor}}>
                                            {questions[selected].answers[2].content}
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="ans" onClick={()=>this.select(questions[selected].answers[3].isTrue)} style={{background:questions[selected].answers[3].contentBG,color:questions[selected].answers[3].contentColor}}>
                                            {questions[selected].answers[3].content}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </> );
    }
}
 
export default Live;