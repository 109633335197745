import React, { Component } from 'react';
class LeftSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {questions,addNewSlide,removeSlide,selected,selectQuestion,changeLiveStatus} = this.props;
        const slidesTemplate = questions.map((item,index)=>{
            let classess = ['slide'];
            if(selected === index){
                classess.push('selected');
            }else{
                if(classess.includes('selected')){
                    classess.shift();
                }

            }
            return(
                <div onClick={() => selectQuestion(index)} className={classess.join(' ')} key={index}>
                    <div className="remove" onClick={() => removeSlide(index)}>
                        <i className="fa fa-trash"></i>
                    </div>
                    <b>{item.content}</b>
                </div>
            )
        });
        return ( <>
        <div className="left-sidebar">
            <div className="head">
                <b> <i className="fa fa-pen"></i> Questions </b>
                <div className="float-end">
                    {questions.length > 0?<button onClick={changeLiveStatus} className="btn btn-outline-success btn-sm"><i className="fa fa-play"></i></button>:null}
                    
                </div>
            </div>
            {slidesTemplate}
            <div className="slide add" onClick={addNewSlide}>
                <b><i className="fa fa-plus"></i></b>
            </div>
        </div>
        </> );
    }
}
 
export default LeftSideBar;