import React, { Component } from 'react';

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            background: '#fff',
            color: '#fff',
         }
    }
   
    render() { 
        const {question,updateTime, updateScore, selected} = this.props;
        return ( <>
            <div className="right-sidebar">
                
                <div className="head">
                    <b><i className="fa fa-cogs"></i> Options </b>
                </div>
                {question?<>
                <div className="form-group">
                    <label> Time </label>
                    <input type="text" value={question.time} onChange={(e)=>updateTime(e,selected)} className="form-control"/>
                </div>
            
                <div className="form-group">
                    <label> Score </label>
                    <input type="text" value={question.score} onChange={(e)=>updateScore(e,selected)} className="form-control"/>
                </div>
                </>:null}
                


            </div>
        </> );
    }
}
 
export default Options;