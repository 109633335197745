import React, { Component } from 'react';
import { ChromePicker } from 'react-color';

class Answers extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            background: '#fff',
            color: '#fff',
         }
    }
   
    render() { 
        const {question: qs,updateAnswerTitle , selected,updateQuestionBG,updateQuestionColor} = this.props;
        return ( <>
            <div className="right-sidebar">
                
                <div className="head">
                    <b><i className="fa fa-cogs"></i> Options </b>
                </div>
            
                <div className="form-group">
                    <label> Content </label>
                    <input type="text" value={qs.content} onChange={(e)=>updateAnswerTitle(e,selected)} className="form-control"/>
                </div>

                <div className="form-group">
                    <label> Background Color </label>
                    <ChromePicker
                        disableAlpha={true}
                        color={ qs.contentBG}
                        onChangeComplete={(color) => updateQuestionBG(color,selected) }
                    />
                </div>

                <div className="form-group">
                    <label> Text Color </label>
                     <ChromePicker
                        disableAlpha={true}
                        color={ this.state.color }
                        onChangeComplete={ (color) => updateQuestionColor(color,selected)  }
                    />
                </div>

            </div>
        </> );
    }
}
 
export default Answers;